
/* PAGE INFO */
#info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #342727;
    padding-top: 5em;
    align-items: center;
    overflow: hidden;
}


#info h2{
    margin-left: auto;
    margin-right: auto; 
    font-size: 64px;
    color: #ffffff;
    text-shadow: 2px 0 0 #FB8417, -2px 0 0 #FB8417, 0 2px 0 #FB8417, 0 -2px 0 #FB8417, 1px 1px #FB8417, -1px -1px 0 #FB8417, 1px -1px 0 #FB8417, -1px 1px 0 #FB8417; 
    font-family: "Chewy" !important;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
}
.giant_burger_cont {
    width: 100%;
    height: 0px;
}

#giant_burger {
    position: relative;
    top: 180px;
    left: 78%;
    width: 912px;
}


.img_div {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*NOUS RETROUVER */
.nous_retrouver{
    color: #FB8417;
    text-align: center ;
    padding-bottom: 65px ;
  
}

.nous_retrouver h1 {
    font-family : "Poppins", sans-serif;
    font-weight: bold;
    font-size: 64px;
}

/* LOGO */


.logo_home_info{

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 65px;

}



/*ADRESSE */

/*10 BOULEVARD DESCARTES */
.rue{
    font-family: "Poppins", sans-serif;
    font-style:normal;
    font-weight: 700;
    line-height: 54px;
    font-size: 30px;
    text-align: center;
    color: #FFFFFF;
    padding-left: 5%;
    margin-bottom: 65px;
}

/* 01 34 34 56 67 */
#Btn_Telephoner_Info{

    padding-top: 20px;
    font-family: 'Poppins' !important;

    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    margin-bottom: 65px;
    background-color:#FB8417;
    font-family: "Poppins";
    border-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(70, 69, 69, 0.25);
    border-radius: 60px;
    padding: 6px 30px;    

}


#Btn_Telephoner_Info:hover{
    
    background-color: #FFFFFF;
    border: 1px solid #F89F4D;
}

.tel :hover{
    color: #FB8417;
}
.tel{
    color: #FFFFFF;
    text-decoration: none; 
}


/* HORRAIRE D'OUVERTURE */


.horaire {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 64px;
}

/* LES HEURES D'OUVERTURE */

.jour{    
   display: inline-block;
} 


.div_horaire {
    display: flex;
    align-items: center;
    font-size: 25px;
}


/*JOUR */
.date_title{
    color: #F89F4D;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    line-height: 40px;
    padding-right: 5px;
}

/*HORAIRE*/
.date_horaire{
    color:white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    line-height: 40px;

}

.Btn_Telephoner_Info {
    width:2px;
}

/*  RESPONSIVITE */

@media screen and (max-width: 1400px) {
    #giant_burger{
        left: 75%;
    } 
}

@media screen and (max-width: 1300px) {
    #giant_burger{
        display: none;
    } 
}

@media screen and (max-width: 950px) {
    .div_horaire {
        font-size: 20px;
    }

    #info h2 {
        font-size:54px;
    }
    .nous_retrouver h1 {
        font-size: 54px;
    }
    .rue{
        font-size: 25px;
    }
}

@media screen and (max-width: 950px) {
    .div_horaire {
        font-size: 20px;
    }

    #info h2 {
        font-size:54px;
    }
    .nous_retrouver h1 {
        font-size: 54px;
    }
    .rue{
        font-size: 25px;
    }
}


@media screen and (max-width: 510px) {
    .div_horaire {
        font-size: 18px;
    }

    #info h2 {
        font-size:44px;
    }
    .nous_retrouver h1 {
        font-size: 44px;
    }
    .rue{
        font-size: 22px;
    }
    .logo_home_info {
        height:70%;
        width:70%;
    }
    #Btn_Telephoner_Info {
        font-size: 26px;
    }
}

@media screen and (max-width: 350px) {
    .div_horaire {
        font-size: 16px;
    }

    #info h2 {
        font-size:34px;
    }
    .nous_retrouver h1 {
        font-size: 34px;
    }
    .rue{
        font-size: 18px;
    }
    .logo_home_info {
        height:60%;
        width:60%;
    }
}
