#title {
  color: #FB8417;
  font-size: 65px;
  font-family : "Poppins", sans-serif;
  font-weight : 1000;
}

#contenant {
  padding-top: 170px;
  background-color: #342727;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-grid {
  padding-top: 50px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px !important;
}

.grid-parent {
  max-width: 100%;
  margin-right: 0px !important;
}

.image_div {
  width:430px;
  height:430px;
}
.image_div img {
  width:100%;
}

.grid-child {
  overflow: hidden;
}

@media screen and (max-width: 1300px) {
  
  #title {
    font-size: 50px;
  }
  .image_div{
    width:400px;
    height:400px;
  }
}

@media screen and (max-width: 1100px) {

  .image_div{
    width:380px;
    height:380px;
  }
}

@media screen and (max-width: 800px) {
  #title {
    font-size: 30px;
  }

  .image_div{
    width:300px;
    height:300px;
  }
}

@media screen and (max-width: 655px) {
  .image_div{
    width:270px;
    height:270px;
  }
}

@media screen and (max-width: 490px) {
  .image_div{
    width:190px;
    height:190px;
  }
}