@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');

.card__item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
  justify-content: space-between;
  height: 320px;
  cursor: pointer;
  width: 320px;
  margin: 1rem;
  background-position: center;
}

.card_contain {
  background-color: white;
  border: solid 5px #FB8417;
  height: 310px;
}

.item_unavailable {
  opacity: 0.5;
}

.MuiFormHelperText-root {
  font-weight: bold;
  color: red;
}

.card__item__heading {
  font-size: 1.5em;
  text-align: center;
  color: #FB8417;
  padding-top: 2%;
  font-family: "Chewy";
  font-weight: 400;
}

.separation_ligne {
  padding: 10px 0;
  border-bottom: 1.2px solid lightgray;
}
.card__item__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__item__image-container img {
  /* taille image */
  width: 230px;
  height: 160px;
  object-fit: contain;
  align-items: center;
  justify-content: center;
}

.card__item__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.card__item__details p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  color: #64A362 !important;
}

.card__item__details i {
  cursor: pointer;
}

.card__item__shopping-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.card__item__commander-btn {
  text-align: center !important;
  /* margin: auto !important; */
  display: flex !important;
  font-family: "Roboto";
}

.myModal__header h1 {
  color: black !important;
}

.myModal__footer {
  justify-content: space-between !important;
  height: 100%;
}

.myModal__footer p {
  font-weight: bold;
}

.myModal__header p {
  font-weight: bold;
}

.myModal__footer div:first-child {
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  -moz-box-shadow-bottom: 2px 2px 2px grey;
  -webkit-box-shadow-bottom: 2px 2px 2px grey;
  box-shadow: 2px 2px 2px grey;
  border-radius: 18px;
  padding: 0.4rem;
  margin: 0 10px;
}

.MuiButtonBase-root {
  padding: 0px !important;
}

.MuiButton-label {
  background:#FB8417!important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  padding: 1.5rem;
  color: white !important;
  font-family: "Roboto";
}
.MuiFormHelperText-root.MuiFormHelperText-root {
  color: red;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

@media (max-width: 1100px) {
  .card__item__heading {
    font-size: 1.2rem;
  }
}

@media (max-width: 545px) {
  .card__item__image-container img {
    /* taille image */
    max-width: 175px;
    max-height: 125px;
    object-fit: contain;
  }
  .card__item__heading {
    font-size: 1.4rem;
  }
  .MuiButton-label {
    font-size: 1rem;
  }
}

/* ce span est pour l'affichage des prix des supplements avec couleurs grey  */
.span_card{

  font-style: none;
  /* font-style: italic; */

  font-size: 1rem;
}

@media (max-width: 400px) {
  .card__item {
    width: 250px;
    color: red !important;
  }
}


@media (max-width: 330px) {
  .card__item {
    width: 250px;
  }
}