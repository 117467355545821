.creation_menus_popup{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    text-align: center;
}
.creation_menus{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
#creation_menus_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.creation_menus_popup .contain{
    background-color: white;
    margin: auto;
    margin-top: 5%;
    width: 80%;
    max-height: 80%;
    overflow-y: scroll;
}

.creation_menus_popup .close{
    position: absolute;
    color: white;
    top: 2%;
    right: 5%;
    font-size: 2em;
}

.creation_menus_popup_button{
    margin: unset;
}
.creation_Title{
    margin-top: 25px;
}

.creation_menus_popup_input{
    width: 25%;
    margin-right: 2%;
    margin-bottom: 0.5%;
    border-radius: 3px;
    border:1px solid
}

.creation_menus_popup ul {
    list-style-type: none;
    margin-top: 1%;
}

.creation_menus_popup select{
    border: 1px solid #E8EAED;
    border-radius: 5px;
}

.creation_menus_popup button.button_inside {
    background-color: #04295d;
    padding: 0.5% 0 0.5% 0;
    color: white;
    width: 10%;
    border: none;
    margin-bottom: 2%;
}

.creation_menus_popup .align{
    width: 100%;
    text-align: center;
}

.creation_menus_popup .align > * {
    max-width: 25%;
    width : 20%;
    display: inline-block;
}

.creation_menus_popup .line{
    position: relative;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 50%;
    height: 2px;
    background-color: #04295d;
}
@media (max-width: 1300px) {
    .creation_menus{
        flex-direction: column;
        margin-top: 250px;
    }
    .creation_menus_popup_input{
        width: 35%;
    }    
}
@media (max-width: 750px) {
    .creation_menus{
        margin-top: 25px;
    }
    #creation_menus_container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 100%; 
    }
    .Button_creation{
        width: 200px !important;
        height: 150px !important;
    }
    .creation_menus_popup_input{
        width: 45%;
    } 
}


