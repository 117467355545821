#header {
    display: flex;
    justify-content: center;
    background:  #342727;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-top: 100px;
    width: 100%;
    padding-left:20px;
    padding-right: 20px;
}

.header_child{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 660px;
}

.burger_div {
    overflow:auto; 
}

.burger_picture {
    height:45em !important;  
    width: auto !important;
}

.header-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height:auto;
    width: auto;    
}

.commanderHeader {
    text-decoration: none;
    color:  #ffffff;
;
}
.commanderHeader:hover {
    color:  #ffffff;
;
}

.Div_Text_Header{
    height: 203px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 80px;
    color: #FB8417;
}

.Div_UnderText{
    color: #ffffff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    max-width: 600px;
}

#Btn_Commander_Accueil{

    margin-top: 50px;
    font-size: 34px;
    background-color: #ffffff;
    color:  #F89F4D;
    width: 330px;
    font-family: 'Poppins';
    border-color: #ffffff;
    border-radius: 30px;
    padding: 8px 30px;    
}

.commanderHeader{
    color: #F89F4D;
}

#Btn_Commander_Accueil:hover {
    background-color: #F89F4D;
    border-color: #F89F4D;
    color: white;
}









@media screen and (max-width: 1500px) {

    .Div_Text_Header{
        font-size: 44px;
    }
    .Div_UnderText{
        font-size: 30px;
    }

}

@media screen and (max-width: 1125px){
    .burger_picture {
        height:615px !important;  
        width: 456px !important;
    }
    .Div_Text_Header{
        font-size: 38px;
    }
    .Div_UnderText{
        font-size: 27px;
    }
    #Btn_Commander_Accueil{
        font-size: 25px;
        width: 230px;
 
    }
}

@media screen and (max-width: 935px) {
    .burger_picture {
        height:546px !important;  
        width: 405px !important;
    }
    .Div_Text_Header{
        font-size: 36px;
    }
    .Div_UnderText{
        font-size: 25px;
    }

}


@media screen and (max-width: 850px) {
    .burger_picture {
        height:444px !important;  
        width: 329px !important;
    }
    .header-container {
        flex-direction: column;
    }

    .Div_Text_Header{
        font-size: 44px;
        line-height: normal;
        max-height:165px;
    }
    .Div_UnderText{
        font-size: 30px;
        line-height: normal;
    }

}


@media screen and (max-width: 510px) {
    .Div_Text_Header{
        font-size: 36px;
    }
    .Div_UnderText{
        font-size: 25px;
    }

}

@media screen and (max-width: 450px) {
    .Div_Text_Header{
        font-size: 32px;
        max-height:145px;
    }
    .Div_UnderText{
        font-size: 20px;
    }
    #Btn_Commander_Accueil{
        font-size: 18px;
        width: 260px;
 
    }
}