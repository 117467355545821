@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');


#plat_title {
  color: #FB8417;
  font-size: 65px;
  font-family : "Poppins", sans-serif;
  font-weight : 1000;
  
}

#plat_contenant {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E5E5E5;
  width: 100% !important;
  overflow-x: hidden;
}

.plat_img-grid {
  max-width: 1300px;
  padding-top: 50px;
  padding-bottom: 20px;
  /* display: block;*/
}

.plat_img_titre {
  
  height: auto;
  margin: 10px;
 
  font-family : "Chewy";
  font-size :54px;
  color :white;
  text-shadow: 2px 0 0 #64A362, -2px 0 0 #64A362, 0 2px 0 #64A362, 0 -2px 0 #64A362, 1px 1px #64A362, -1px -1px 0 #64A362, 1px -1px 0 #64A362, -1px 1px 0 #64A362;
 
  max-height: 100px;

}


.plat_img_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #342727 ;
  height: 330px;
  width: 300px;
}

.plat_img_div img{
  max-height: 50%;
  max-width:100%;
  padding-top: 10px;
  height: 50%;
  /* To centering the images on any resolution*/
  margin-left: auto;
  margin-right: auto
}

.plat_grid-child {
  height: auto;
}



@media screen and (max-width: 1280px) and (min-width:850px) {
  .plat_grid-parent {
    width:920px !important;
  }
  
}

@media screen and (max-width: 850px) {
  .plat_grid-parent {
    width:auto ;
  }
  #plat_title {
    font-size:45px;
  }
}

@media screen and (max-width:500px) {
  .plat_img_div {
    height: 230px;
  }
}

/********** Button Command ************/


.command_button{
  padding: 2em;
}

.telecharger_btn {

  position: flex;
  width: 100%;
  height: 62px;
  padding: 10px 30px;

  display: block;
  margin-left: auto;
  margin-right: auto;
  

  font-family: "Poppins" !important;
  text-decoration: none !important;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 36px;
  text-align: center;

  color: #FFFFFF;

  
  

  background: #FB8417;
  border: 1px solid #FB8417;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 60px;

}

.telecharger_btn:hover {
  background-color: #ffffff;
  color: #FB8417;
  border: 1px solid #ffffff;

}

/********** Button Command ************/