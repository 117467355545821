@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.carte {
  box-sizing: border-box;
  padding: 1em 1em 2em 1em;
  margin-left: 2.5rem;
  height: max-content;
  margin-right: 2.5rem;
  background-position: center;
}

.carte_contain {
  background-color: #F89F4D;
  border: solid 4px white;
  margin-left: 2em;
  margin-top: 2em;
}

.carteTitle {
  color: white;
  font-family: "Chewy" !important;
  text-align: center;
  font-size: 44px !important;
  text-shadow: 2px 0 0 #64A362, -2px 0 0 #64A362, 0 2px 0 #64A362, 0 -2px 0 #64A362, 1px 1px #64A362, -1px -1px 0 #64A362, 1px -1px 0 #64A362, -1px 1px 0 #64A362;
  font-weight: normal  !important;
  line-height: 84px;
  letter-spacing: 15% !important;
}
.carteStar {
  width: 30px !important;
}

.carte__image-container {
  display: flex;
  justify-content: center;
  padding: 0.1em;
}

.carte__image-container img {
  width: 102px;
  height: 93px;
  object-fit: contain;
}

.carte_title {
  font-style: normal;
  font-size: 2.5rem;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.02em;
}

.carte__items {
  margin-top: 15px;
  padding: 0 25px 1em;
  text-align: center;
  margin-bottom: -1em;
}

.carte__item {
  color: white;
  padding: 5px 0;
  cursor: pointer;
  position: relative;
  font-size: 1.5rem;
  border-radius: 5px;
  text-align: left;
  font-family: "Poppins" !important;
  font-weight: 700;
  width: 240px;
}

.carte__item:hover {
  color:#241a1a;
}

.carte__item::before {
  position: absolute;
  left: -50px;
  width: 40px;
  height: 40px;
  content: "";
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  top: -1px;
  display: none;
}

.carte__item.active::before {
  display: block;
}
.carte__item.active {
  font-size: 1.5rem;
  font-weight: 700;
}


.carte.active {
  height: 100%;
}

@media (max-width: 1080px) {
  .carte {
    position: absolute;
    top: 0;
    left: -120%;
    padding-top: 2rem;
    /* left: -500px; */
    width: 100%;
    z-index: 99;
    transition: all 0.5s;
  }

  .carte.active {
    left: 0px;
    overflow: visible;
    margin-left: 0px !important;
  }

  .carte__item {
    color: rgb(238, 238, 238);
  }

  .commander__carte i {
    position: absolute;
    left: 2rem;
    margin-bottom: -4em;
    color: rgb(238, 238, 238);
  }
}

@media (max-width: 960px) {
  .carte_contain {
    margin-left: 0px;
  }
}

@media (max-width: 800px) {

  .carte__image-container img {
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .carte_title {
    line-height: normal;
  }
  
}

@media (max-height: 700px) {
  .carte_title {
    display: none;
  }
  .carte__item {
    margin-bottom: 0px;
  }
}
@media (max-width: 500px) {
  .commander__carte i {
    position: absolute;
    left: 14px;
    margin-bottom: -2em;
  }
}

@media (min-width: 1080px) {
.commander__container__cards {
    display: flex;
    align-items: flex-start;
    align-content: start;
    width: 100%;
    margin-top: 10px;
}
}

/* for carte not backdrop */
.modal-backdrop{
    position: inherit !important;
}

/* style for carte side */
.menu-side {
  padding-top: 6em;
}

/* Arrow icon for Menu Side */
.menu-arrow-back .MuiSvgIcon-root {
  fill: white;
  font-size: 50px;
  /* margin: 0.3em; */
  margin-top: 35px;
  
}


.menu-arrow-forward .MuiSvgIcon-root {
  fill: white;
  font-size: 50px;
  position: fixed;
  margin-top: -3px;
  margin-left: 62px;
}
.menu-arrow-forward {
  color: white;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  width: 110px;
  align-items: center;
  border: 2px solid white;
  border-radius: 10px;
  position: fixed;
  margin-bottom: 30px;
      
}
.menu-arrow-forward > p  {
  margin-bottom: 0.5rem !important;
  margin: 5px;
  font-weight: bolder;
}
.menu-offcanvas .offcanvas-body {
  background-color: #241a1a;
  padding: 0rem 0rem;
  display: flex;
  justify-content: center;
  
}

/***side-bar menu globe icon ****/
.globeIcon{
  cursor: pointer;
  padding-right: 15px;
  opacity: 0;
  width: 250px;
  height:45px;
  position: absolute;
}


.globeIcon:hover{
  height:auto !important;
  width:auto !important;
  position: relative;
  opacity: 1;
}